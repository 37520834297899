import { graphql, Link } from "gatsby"
import React from "react"
// import { makeBlogPath } from "../utils"
// import dateformat from "dateformat"
import moment from "moment";
import SEONOINDEX from '../components/seonoindex'
import Layout from '../components/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


class PostAuthor extends React.Component {

  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.postbyauthor
    const author = data.privateGraphql.getauthor
    const { currentPage, numPagesa } = this.props.pageContext
    const authorname = author.profilename;
    const bio = author.bio;
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPagesa
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    if (location != null) {
      if (location.href != null && location.href.endsWith('/')) {
        window.location.href = window.location.href.substring(0, window.location.href.length - 1);
      }
    }
    return (
      <Layout location={this.props.location} title={authorname}>
        <SEONOINDEX
          title={`${authorname}`}
          description={`${bio}`}
          canonical={`https://${domainName}/authors/${author.slugname}`}
          authorName={authorname}
          authordescription={bio}
          authorimage={ author.image}
          authorSocial={author.twitter}
        />
        <div className="col-lg-8 col-md-12">
        <div className="row">
          <header className="tb-header col-md-10 m-auto text-center">
            <h1 className="w-page-header__headline">{authorname}</h1>
            <p className="w-page-header__copy">
              {author.biowithhtml ?
                <section dangerouslySetInnerHTML={{ __html: author.biowithhtml }} />
                :
                bio
              }
            </p>
          </header>
        </div>
        <div className="row">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="col-md-6" key={node.slugtitle}>
                <article className="archive-post mts-post">
                  <Link to={node.slugtitle} title={title} className="post-image post-image-full">
                    <div className="featured-thumbnail">
                      <LazyLoadImage
                        className="wp-post-image"
                        alt={title}
                        title={title}
                        src={ node.image}
                        effect="blur"
                      />
                    </div>				
                    </Link>
                  <div className="post-content">
                    <div className="post-meta">
                      <span className="theauthor">
                          <Link to={`/authors/${node.profileSlugName}`}>
                            <LazyLoadImage
                            className="author-avatar"
                            title={node.profileName}
                            src={ node.profileImage}
                            effect="blur"
                          />
                          {node.profileName}
                          </Link>
                        </span>
                        <span className="thecategory">{node.categoryName !== null ? <a href={`https://${domainName}/` + node.slugCategoryName}>{node.categoryName}</a> : "คู่มือการซื้อขาย"}</span>
                      
                    </div>
                    <header>
                      <h2 className="post-title">
                      <a href={`https://${domainName}/` + node.slugtitle} title={title}>{title}</a></h2>
                    </header>
                    <div className="post-excerpt">
                      <p className="tb-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                      <Link to={node.slugtitle} title={title} className="read-more-link">อ่านเพิ่มเติม</Link>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        <div className="row pagination">
          <ul className="col d-flex justify-content-center">
            {!isFirst && (
              <li className="w-pagination__link">
                <Link to={curr <= 2 ? `/authors/${author.slugname}` : `/authors/${author.slugname}/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </li>
            )}
            {Array.from({ length: numPagesa }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="tp-pagination__link"
                  >
                    <Link
                      to={`/authors/${author.slugname}${i === 0 ? '' : '/page/' + (i + 1)}`}
                      className={ i + 1 === curr ? "current":"page-s"}
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              }
            })}
            {!isLast && (
              <li className="tp-pagination__link">
                <Link to={`/authors/${author.slugname}/page/${nextPage}`} rel="next">
                  →
                </Link>
              </li>
            )}
          </ul>
        </div>
        </div>
      </Layout>
    )
  }
}
export default PostAuthor
export const query = graphql`
query ($author: String,$limit: Int = 21, $skip: Int = 0, $siteId: Int = 1066) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    getauthor(slugtitle: $author, siteId: $siteId) {
      id
      profilename
      slugname
      image
      bio
      twitter
      biowithhtml
    }
    postbyauthor(
      slugtitle: $author
      limit: $limit 
      skip: $skip
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
